export const PEOPLE_SORT_OPTIONS = [
  {
    label: 'Most Photos',
    param: 'files_count_desc'
  },
  {
    label: 'Least Photos',
    param: 'files_count_asc'
  },
  {
    label: 'Last Name (A > Z)',
    param: 'last_name_asc'
  },
  {
    label: 'Last Name (Z > A)',
    param: 'last_name_desc'
  },
  {
    label: 'First Name (A > Z)',
    param: 'first_name_asc'
  },
  {
    label: 'First Name (Z > A)',
    param: 'first_name_desc'
  }
];

export const PEOPLE_DISPLAY_STATUS = {
  visible: 'visible',
  hidden: 'hidden',
  unqualified: 'unqualified'
};

export const PEOPLE_NAMED_STATUS = {
  named: 'named',
  unnamed: 'unnamed'
};

export const PEOPLE_NAMED_STATUS_OPTIONS = [
  {
    label: 'All',
    param: null
  },
  {
    label: 'Named',
    param: PEOPLE_NAMED_STATUS.named
  },
  {
    label: 'Unnamed',
    param: PEOPLE_NAMED_STATUS.unnamed
  },
];

export const FACIAL_REC_STATUS = {
  not_started: 'not_started',
  pending: 'pending',
  bulk_indexing: 'bulk_indexing',
  processing: 'processing',
  complete: 'complete'
};

export const FACIAL_REC_STATUS_POLL_INTERVAL = {
  not_started: 45_000,
  pending: 30_000,
  bulk_indexing: 30_000,
  processing: 15_000,
  complete: 60_000
};

export const FACIAL_REC_UPSELL_BANNER_DISMISS_PERIOD = 14 * 24 * 60 * 60 * 1000; // 14 days
export const FACIAL_REC_UPSELL_BANNER_KEY = 'facial-rec-upsell-banner-dismissed';


export const PEOPLE_PRONOUN_OPTIONS = [
  {
    value: 'he/him/his',
    label: 'He/Him/His',
    subject: 'he',
    object: 'him',
    possessive_single: 'his',
    possessive: 'his',
    reflexive: 'himself'
  },
  {
    value: 'she/her/hers',
    label: 'She/Her/Hers',
    subject: 'she',
    object: 'her',
    possessive_single: 'her',
    possessive: 'hers',
    reflexive: 'herself'
  },
  {
    value: 'they/them/their',
    label: 'They/Them/Theirs',
    subject: 'they',
    object: 'them',
    possessive_single: 'their',
    possessive: 'theirs',
    reflexive: 'themself'
  },
  {
    value: 'unspecified',
    label: 'Other',
    subject: 'they',
    object: 'them',
    possessive_single: 'their',
    possessive: 'theirs',
    reflexive: 'themself'
  },
];

export const PERSON_GENDER = {
  male: 'male',
  female: 'female',
  unknown: 'unknown',
  unspecified: 'unspecified'
}
export const PERSON_GENDER_OPTIONS = [
  {
    value: PERSON_GENDER.male,
    label: 'Male'
  },
  {
    value: PERSON_GENDER.female,
    label: 'Female'
  },
  {
    value: PERSON_GENDER.unknown,
    label: 'Unknown'
  }
];
export const PERSON_GENDER_DEFAULT = PERSON_GENDER.unspecified;

export const PERSON_LIFE_STATUS = {
  living: 'living',
  deceased: 'deceased'
};
export const PERSON_LIFE_STATUS_OPTIONS = [
  {
    value: PERSON_LIFE_STATUS.living,
    label: 'Living'
  },
  {
    value: PERSON_LIFE_STATUS.deceased,
    label: 'Deceased'
  }
];
export const PERSON_LIFE_STATUS_DEFAULT = PERSON_LIFE_STATUS.living;

export const PERSON_NAME_TYPE = {
  birth_name: 'birth name',
  married_name: 'married name',
  also_known_as: 'also known as'
};
export const PERSON_NAME_TYPE_OPTIONS = [
  {
    value: PERSON_NAME_TYPE.birth_name,
    label: 'Birth Name'
  },
  {
    value: PERSON_NAME_TYPE.married_name,
    label: 'Married Name'
  },
  {
    value: PERSON_NAME_TYPE.also_known_as,
    label: 'Also Known As'
  }
];

export const PERSON_NAME_TYPE_DEFAULT = PERSON_NAME_TYPE.birth_name;

export const PEOPLE_EVENTS = {
  navigationRefresh: 'people-nav-refresh'
};
